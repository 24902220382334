<template>
  <b-tabs content-class="pt-1" pills>
    <b-tab active>
      <template #title>
        <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">淘客帐号配置</span>
      </template>
      <b-row class="match-height">
        <b-col md="6">
          <b-card-code>
            <b-form>
              <b-row>
                <!-- Field: Username -->
                <b-col cols="12">
                  <b-form-group
                    label="阿里妈妈账号"
                    label-for="alimama_accountid"
                  >
                    <b-form-input id="wechatid" v-model="alimama_accountid" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Full Name -->
                <b-col cols="12">
                  <b-form-group label="大淘客key" label-for="dataoke_appkey">
                    <b-form-input
                      id="dataoke_appkey"
                      v-model="dataoke_appkey"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="大淘客secret"
                    label-for="dataoke_appsecret"
                  >
                    <b-form-input
                      id="dataoke_appsecret"
                      v-model="dataoke_appsecret"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Full Name -->
                <b-col cols="12">
                  <b-form-group>
                    <div style="float: left; width: 120px">
                      <label label-for="nickname">是否支持渠道跟单:</label>
                    </div>
                    <div style="float: left">
                      <b-form-checkbox
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        v-model="rcheck"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="京东联盟id" label-for="jd_unionid">
                    <b-form-input id="jd_unionid" v-model="jd_unionid" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Full Name -->
                <b-col cols="12">
                  <b-form-group label="京东key" label-for="jd_appkey">
                    <b-form-input id="jd_appkey" v-model="jd_appkey" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="京东有效期" label-for="deviceid">
                    <b-form-input
                      id="deviceid"
                      v-model="jd_appkey_expire_time"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col cols="12">
                  <b-form-group label="拼多多clientid" label-for="clientid">
                    <b-form-input id="clientid" v-model="clientid" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                
                <b-col cols="12">
                  <b-form-group label="clientsecret" label-for="clientsecret">
                    <b-form-input id="clientsecret" v-model="clientsecret" />
                  </b-form-group>
                </b-col>
              </b-row> -->
              <b-row>
                <b-col cols="12">
                  <b-form-group label="美团AppKey" label-for="meituanappkey">
                    <b-form-input id="meituanappkey" v-model="meituanappkey" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="美团Secret" label-for="meituansecret">
                    <b-form-input id="meituansecret" v-model="meituansecret" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="守兔电影票AppKey"
                    label-for="pintotoappKey"
                  >
                    <b-form-input id="pintotoappKey" v-model="pintotoappKey" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="守兔电影票Secret"
                    label-for="pintotosecret"
                  >
                    <b-form-input id="pintotosecret" v-model="pintotosecret" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="聚推客pub_id" label-for="jutuikepubid">
                    <b-form-input id="jutuikepubid" v-model="jutuikepubid" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="save()"
              >
                保 存
              </b-button>

              <b-button
                variant="outline-secondary"
                type="reset"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                重 置
              </b-button>
              <!-- Field: Full Name -->
            </b-form>
            <!-- Action Buttons -->
          </b-card-code>
        </b-col>
        <b-col md="6">
          <b-card-code>
            <b-form>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="联联key" label-for="lianlianchannid">
                    <b-form-input
                      id="lianlianchannid"
                      v-model="lianlianchannid"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="联联Secret" label-for="lianliansecret">
                    <b-form-input
                      id="lianliansecret"
                      v-model="lianliansecret"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Username -->
                <b-col cols="12">
                  <b-form-group
                    label="阿里妈妈账号"
                    label-for="alimama_accountid"
                  >
                    <b-form-input id="wechatid" v-model="alimama_accountid" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Full Name -->
                <b-col cols="12">
                  <b-form-group label="大淘客key" label-for="dataoke_appkey">
                    <b-form-input
                      id="dataoke_appkey"
                      v-model="dataoke_appkey"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="大淘客secret"
                    label-for="dataoke_appsecret"
                  >
                    <b-form-input
                      id="dataoke_appsecret"
                      v-model="dataoke_appsecret"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="二维码跳转方式"
                    label-for="dataoke_appsecret"
                  >
                    <v-select
                      v-model="tzlink"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="tzoption"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="H5跳转二维码网址"
                    label-for="wxqrcodelink"
                  >
                    <b-form-input id="wxqrcodelink" v-model="wxqrcodelink" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="小程序跳转二维码地址"
                    label-for="weappqrcodelink"
                  >
                    <b-form-input
                      id="weappqrcodelink"
                      v-model="weappqrcodelink"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="好单库apikey" label-for="haodankuapikey">
                    <b-form-input
                      id="haodankuapikey"
                      v-model="haodankuapikey"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="好单库V3apikey"
                    label-for="haodankuV3apikey"
                  >
                    <b-form-input
                      id="haodankuV3apikey"
                      v-model="haodankuV3apikey"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="好单库V3Secret"
                    label-for="haodankuV3secret"
                  >
                    <b-form-input
                      id="haodankuV3secret"
                      v-model="haodankuV3secret"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="聚推客apikey" label-for="jutuikeapikey">
                    <b-form-input id="jutuikeapikey" v-model="jutuikeapikey" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-card-code>
        </b-col>
      </b-row>
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">提示语配置</span>
      </template>
      <b-row class="match-height">
        <b-col md="6">
          <b-card-code>
            <b-form>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="订单关闭提示语"
                    label-for="CloseOrderSay"
                  >
                    <b-form-input id="CloseOrderSay" v-model="CloseOrderSay" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="订单成功提示语"
                    label-for="PaymentOrderSay"
                  >
                    <b-form-input
                      id="PaymentOrderSay"
                      v-model="PaymentOrderSay"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="订单结算提示语"
                    label-for="SettlementOrderSay"
                  >
                    <b-form-input
                      id="SettlementOrderSay"
                      v-model="SettlementOrderSay"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="订单退款提示语"
                    label-for="OrderCancellSay"
                  >
                    <b-form-input
                      id="OrderCancellSay"
                      v-model="OrderCancellSay"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="群订单下单群主通知"
                    label-for="chatroomPaymentOrderSay"
                  >
                    <b-form-input
                      id="chatroomPaymentOrderSay"
                      v-model="chatroomPaymentOrderSay"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="群订单结算群主通知"
                    label-for="chatroomSettlementOrderSay"
                  >
                    <b-form-input
                      id="chatroomSettlementOrderSay"
                      v-model="chatroomSettlementOrderSay"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="群订单关闭群主通知"
                    label-for="chatroomOrderClose"
                  >
                    <b-form-input
                      id="chatroomOrderClose"
                      v-model="chatroomOrderClose"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group
                    label="群订单退款群主通知"
                    label-for="chatroomOrderCancellSay"
                  >
                    <b-form-input
                      id="chatroomOrderCancellSay"
                      v-model="chatroomOrderCancellSay"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Full Name -->
                <b-col cols="12">
                  <b-form-group label="上级提成通知" label-for="agentSay">
                    <b-form-input id="agentSay" v-model="agentSay" />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Field: Full Name -->
            </b-form>

            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="save1()"
            >
              保 存
            </b-button>

            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              重 置
            </b-button>
          </b-card-code>
        </b-col>

        <b-col md="6">
          <b-card-code>
            <b-row>
              <!-- Field: Username -->
              <b-col cols="12">
                <b-form-group label="搜索等待语" label-for="waitkey">
                  <b-form-input id="waitkey" v-model="waitkey" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Full Name -->
              <b-col cols="12">
                <b-form-group label="口令形式" label-for="kltype">
                  <v-select
                    v-model="kltype"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="option"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Full Name -->
              <b-col cols="12">
                <b-form-group label="淘宝自定义详情页链接" label-for="url">
                  <b-form-input id="url" v-model="url" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Full Name -->
              <b-col cols="12">
                <b-form-group
                  label="无优惠是否推荐同款其他商品"
                  label-for="sousuo"
                >
                  <v-select
                    v-model="tjtype"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="tjoption"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-code>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">路径配置</span>
      </template>
      <b-row class="match-height">
        <b-col md="6">
          <b-card-code>
            <b-form>
              <b-row>
                <!-- Field: Username -->
                <b-col cols="12">
                  <b-form-group label="H5域名" label-for="waitkey">
                    <b-form-input id="h5url" v-model="h5url" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="京东路径" label-for="jdurl">
                    <b-form-input id="jdurl" v-model="jdurl" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="淘宝路径" label-for="tburl">
                    <b-form-input id="tburl" v-model="tburl" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="拼多多路径" label-for="pddurl">
                    <b-form-input id="pddurl" v-model="pddurl" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="唯品会路径" label-for="wphurl">
                    <b-form-input id="wphurl" v-model="wphurl" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="搜素路径" label-for="sousuourl">
                    <b-form-input id="sousuourl" v-model="sousuourl" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="公众号名称" label-for="gzhname">
                    <b-form-input id="gzhname" v-model="gzhname" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="公众号appid" label-for="gzhappid">
                    <b-form-input id="gzhappid" v-model="gzhappid" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: Email -->
                <b-col cols="12">
                  <b-form-group label="公众号原始id" label-for="gzhid">
                    <b-form-input id="gzhid" v-model="gzhid" />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Field: Full Name -->
            </b-form>

            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="save2()"
            >
              保 存
            </b-button>

            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              重 置
            </b-button>
          </b-card-code>
        </b-col>
        <b-col md="6">
          <b-card-code>
            <b-row>
              <!-- Field: Email -->
              <b-col cols="12">
                <b-form-group label="小程序id" label-for="appid">
                  <b-form-input id="appid" v-model="appid" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Email -->
              <b-col cols="12">
                <b-form-group label="小程序Secret" label-for="appSecret">
                  <b-form-input
                    id="appSecret"
                    type="password"
                    v-model="appSecret"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Email -->
              <b-col cols="12">
                <b-form-group label="小程序Token" label-for="appToken">
                  <b-form-input id="appToken" v-model="appToken" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Email -->
              <b-col cols="12">
                <b-form-group label="小程序AESKEY" label-for="appAESKey">
                  <b-form-input id="appAESKey" v-model="appAESKey" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Email -->
              <b-col cols="12">
                <b-form-group label="小程序名称" label-for="source">
                  <b-form-input id="source" v-model="source" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Email -->
              <b-col cols="12">
                <b-form-group label="小程序原始ID" label-for="sourcename">
                  <b-form-input id="sourcename" v-model="sourcename" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Email -->
              <b-col cols="12">
                <b-form-group label="小程序icon" label-for="sourcename">
                  <b-form-input id="icon" v-model="icon" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Email -->
              <b-col cols="12">
                <b-form-group label="聚推客cms首页" label-for="sourcename">
                  <b-form-input id="jutuikeindex" v-model="jutuikeindex" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-code>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">佣金配置</span>
      </template>
      <b-card-code>
        <b-form>
          <b-row>
            <!-- Field: Username -->
            <b-col cols="12">
              <b-form-group label="是否返佣" label-for="isfan">
                <v-select
                  v-model="isfan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="isfanoption"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group label="佣金比例" label-for="commission">
                <b-input-group append="%">
                  <b-form-input v-model="commission" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group label="购买用户佣金占比" label-for="useryongjin">
                <b-input-group append="%">
                  <b-form-input v-model="useryongjin" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group label="直属佣金占比" label-for="zhishuyongjin">
                <b-input-group append="%">
                  <b-form-input v-model="zhishuyongjin" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group label="非直属佣金占比" label-for="feizhishuyongjin">
                <b-input-group append="%">
                  <b-form-input v-model="feizhishuyongjin" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group label="电影票金额占比" label-for="moivezsyj">
                <b-input-group append="%">
                  <b-form-input v-model="moivebl" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group label="电影直属佣金占比" label-for="moivezsyj">
                <b-input-group append="%">
                  <b-form-input v-model="moivezsyj" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Field: Full Name -->
        </b-form>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="save3()"
        >
          保 存
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          重 置
        </b-button>
      </b-card-code>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">支付配置</span>
      </template>
      <b-card-code>
        <b-form>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group label="微信支付商户号" label-for="mchId">
                <b-input-group>
                  <b-form-input v-model="mchId" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group label="微信支付商户密钥" label-for="mchKey">
                <b-input-group>
                  <b-form-input v-model="mchKey" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group label="上传证书" label-for="mchKey">
                <b-form-file
                  v-model="file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Field: Full Name -->
        </b-form>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="save4()"
        >
          保 存
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          重 置
        </b-button>
      </b-card-code>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTabs,
  BTab,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BInputGroup,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";

import vSelect from "vue-select";
import fetch from "@/utils/fetch";
import useJwt from "@/auth/jwt/useJwt";
import BCardCode from "@core/components/b-card-code";
export default {
  components: {
    BButton,
    BMedia,
    BCardCode,
    BAvatar,
    BTabs,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormFile,
    BFormCheckbox,
    vSelect,
  },

  methods: {
    save() {
      this.$swal({
        title: "确定要保存吗?",
        text: "保存返利配置信息!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (this.ck1 == false) {
          useJwt
            .AlimamaInsert({
              cid: this.cid,
              alimamaAccountid: this.alimama_accountid,
              jdUnionid: this.jd_unionid,
              jdAppkey: this.jd_appkey,
              jdAppkeyExpireTime: this.jd_appkey_expire_time,
              dataokeAppkey: this.dataoke_appkey,
              dataokeAppsecret: this.dataoke_appsecret,
              supportRelationid: this.rcheck == true ? 1 : 0,
              pddclientid: this.clientid,
              pddclientsecret: this.clientsecret,
              meituanappkey: this.meituanappkey,
              meituansecret: this.meituansecret,
              pintotoappKey: this.pintotoappKey,
              pintotosecret: this.pintotosecret,
              lianlianchannid: this.lianlianchannid,
              lianliansecret: this.lianliansecret,
              jutuikeapikey: this.jutuikeapikey,
              haodankuapikey: this.haodankuapikey,
              haodankuV3apikey: this.haodankuV3apikey,
              haodankuV3secret: this.haodankuV3secret,
              weappqrcodelink: this.weappqrcodelink,
              wxqrcodelink: this.wxqrcodelink,
              jutuikepubid: this.jutuikepubid,
              qrcodetype: this.tzlink.value,
            })
            .then((res) => {
              console.log("res", JSON.stringify(res));
              if (res.data.code == 0) {
                this.$swal({
                  icon: "success",
                  title: "保存!",
                  text: "保存成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "保存失败!",
                  text: "请联系管理员处理",
                  customClass: {
                    confirmButton: "btn btn-error",
                  },
                });
              }
            });
        } else {
          useJwt
            .Alimamaupdate({
              cid: this.cid,
              alimamaAccountid: this.alimama_accountid,
              jdUnionid: this.jd_unionid,
              jdAppkey: this.jd_appkey,
              jdAppkeyExpireTime: this.jd_appkey_expire_time,
              dataokeAppkey: this.dataoke_appkey,
              dataokeAppsecret: this.dataoke_appsecret,
              supportRelationid: this.rcheck == true ? 1 : 0,
              pddclientid: this.clientid,
              pddclientsecret: this.clientsecret,
              meituanappkey: this.meituanappkey,
              meituansecret: this.meituansecret,
              pintotoappKey: this.pintotoappKey,
              pintotosecret: this.pintotosecret,
              lianlianchannid: this.lianlianchannid,
              lianliansecret: this.lianliansecret,
              jutuikeapikey: this.jutuikeapikey,
              haodankuapikey: this.haodankuapikey,
              haodankuV3apikey: this.haodankuV3apikey,
              haodankuV3secret: this.haodankuV3secret,
              qrcodetype: this.tzlink.value,
              weappqrcodelink: this.weappqrcodelink,
              wxqrcodelink: this.wxqrcodelink,
              jutuikepubid: this.jutuikepubid,
            })
            .then((res) => {
              console.log("res", JSON.stringify(res));
              if (res.data.code == 0) {
                this.$swal({
                  icon: "success",
                  title: "保存!",
                  text: "保存成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "保存失败!",
                  text: "请联系管理员处理",
                  customClass: {
                    confirmButton: "btn btn-error",
                  },
                });
              }
            });
        }
      });
    },
    save1() {
      this.$swal({
        title: "确定要保存吗?",
        text: "保存配置信息!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (this.ck == false) {
          useJwt
            .RebateConfigInsert({
              cid: this.cid,
              searchwait: this.waitkey,
              commandtype: this.kltype.value,
              tjtype: this.tjtype.value,
              ordersucceeded: this.PaymentOrderSay,
              ordersettlement: this.SettlementOrderSay,
              ordersrefund: this.OrderCancellSay,
              ordersclose: this.CloseOrderSay,
              chatroomPaymentOrderSay: this.chatroomPaymentOrderSay,
              chatroomSettlementOrderSay: this.chatroomSettlementOrderSay,
              chatroomOrderCancellSay: this.chatroomOrderCancellSay,
              chatroomOrderClose: this.chatroomOrderClose,
              agentrate: this.agentSay,
              url: this.url,
            })
            .then((res) => {
              console.log("this.kltype", JSON.stringify(this.kltype));
              if (res.data.code == 0) {
                this.ck = true;
                this.$swal({
                  icon: "success",
                  title: "保存!",
                  text: "保存成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "保存失败!",
                  text: "请联系管理员处理",
                  customClass: {
                    confirmButton: "btn btn-error",
                  },
                });
              }
            });
        } else {
          useJwt
            .RebateConfigUpdate({
              cid: this.cid,
              searchwait: this.waitkey,
              commandtype: this.kltype.value,
              tjtype: this.tjtype.value,
              ordersucceeded: this.PaymentOrderSay,
              ordersettlement: this.SettlementOrderSay,
              ordersrefund: this.OrderCancellSay,
              ordersclose: this.CloseOrderSay,
              chatroomPaymentOrderSay: this.chatroomPaymentOrderSay,
              chatroomSettlementOrderSay: this.chatroomSettlementOrderSay,
              chatroomOrderCancellSay: this.chatroomOrderCancellSay,
              chatroomOrderClose: this.chatroomOrderClose,
              agentrate: this.agentSay,
              url: this.url,
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$swal({
                  icon: "success",
                  title: "保存!",
                  text: "保存成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "保存失败!",
                  text: "请联系管理员处理",
                  customClass: {
                    confirmButton: "btn btn-error",
                  },
                });
              }
            });
        }
      });
    },

    save2() {
      this.$swal({
        title: "确定要保存吗?",
        text: "保存配置信息!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("his.ck", this.ck);
        if (this.ck == false) {
          useJwt
            .RebateConfigInsertweapp({
              cid: this.cid,
              h5url: this.h5url,
              jdurl: this.jdurl,
              tburl: this.tburl,
              pddurl: this.pddurl,
              wphurl: this.wphurl,
              appid: this.appid,
              appSecret: this.appSecret,
              appToken: this.appToken,
              appAESKey: this.appAESKey,
              source: this.source,
              sourcename: this.sourcename,
              sousuourl: this.sousuourl,
              icon: this.icon,
              gzhname: this.gzhname,
              gzhid: this.gzhid,
              gzhappid: this.gzhappid,
              jutuikeindex: this.jutuikeindex,
            })
            .then((res) => {
              console.log("this.kltype", JSON.stringify(this.kltype));
              if (res.data.code == 0) {
                this.ck = true;
                this.$swal({
                  icon: "success",
                  title: "保存!",
                  text: "保存成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "保存失败!",
                  text: "请联系管理员处理",
                  customClass: {
                    confirmButton: "btn btn-error",
                  },
                });
              }
            });
        } else {
          useJwt
            .RebateConfigUpdateWeapp({
              cid: this.cid,
              h5url: this.h5url,
              jdurl: this.jdurl,
              tburl: this.tburl,
              pddurl: this.pddurl,
              wphurl: this.wphurl,
              appid: this.appid,
              appSecret: this.appSecret,
              appToken: this.appToken,
              appAESKey: this.appAESKey,
              source: this.source,
              sourcename: this.sourcename,
              sousuourl: this.sousuourl,
              icon: this.icon,
              gzhname: this.gzhname,
              gzhid: this.gzhid,
              gzhappid: this.gzhappid,
              jutuikeindex: this.jutuikeindex,
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$swal({
                  icon: "success",
                  title: "保存!",
                  text: "保存成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "保存失败!",
                  text: "请联系管理员处理",
                  customClass: {
                    confirmButton: "btn btn-error",
                  },
                });
              }
            });
        }
      });
    },
    save3() {
      this.$swal({
        title: "确定要保存吗?",
        text: "保存配置信息!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (this.ck == false) {
          useJwt
            .RebateConfigInsertyongjin({
              cid: this.cid,
              isfan: this.isfan,
              commission: this.commission,
              useryongjin: this.useryongjin,
              zhishuyongjin: this.zhishuyongjin,
              feizhishuyongjin: this.feizhishuyongjin,
              moivezsyj: this.moivezsyj,
              moivebl: this.moivebl,
            })
            .then((res) => {
              console.log("this.kltype", JSON.stringify(this.kltype));
              if (res.data.code == 0) {
                this.ck = true;
                this.$swal({
                  icon: "success",
                  title: "保存!",
                  text: "保存成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "保存失败!",
                  text: "请联系管理员处理",
                  customClass: {
                    confirmButton: "btn btn-error",
                  },
                });
              }
            });
        } else {
          console.log(" this.zhishuyongjin", this.zhishuyongjin);
          useJwt
            .RebateConfigupdatebyyongjin({
              cid: this.cid,
              isfan: this.isfan.value,
              commission: this.commission,
              useryongjin: this.useryongjin,
              zhishuyongjin: this.zhishuyongjin,
              feizhishuyongjin: this.feizhishuyongjin,
              moivezsyj: this.moivezsyj,
              moivebl: this.moivebl,
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$swal({
                  icon: "success",
                  title: "保存!",
                  text: "保存成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "保存失败!",
                  text: "请联系管理员处理",
                  customClass: {
                    confirmButton: "btn btn-error",
                  },
                });
              }
            });
        }
      });
    },
    async save4() {
      const file = this.file;
      console.log("file", file);
      if (this.file != null) {
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `上传文件大小不能超过 2MB!`,
                icon: "XCircleIcon",
                variant: "success",
                text: ``,
              },
            },
            {
              position: "top-center",
            }
          );
          return;
        }
        let formData = new FormData();
        formData.append("myfile", file);
        formData.append("isUploadFile", true);
        console.log("formData", formData);
        await fetch.fileUpload("/CertUpload", formData).then(({ data }) => {
          console.log("data", JSON.stringify(data));
          console.log(data.msg);
          if (data.msg === "success") {
            this.certname = data.data.certname;
          }
        });
      }
      if (this.ck == false) {
        await useJwt
          .RebateConfiginsertwxpay({
            cid: this.cid,
            mchId: this.mchId,
            mchKey: this.mchKey,
            keyPath: this.certname,
          })
          .then((res) => {
            console.log("this.kltype", JSON.stringify(this.kltype));
            if (res.data.code == 0) {
              this.ck = true;
              this.$swal({
                icon: "success",
                title: "保存!",
                text: "保存成功",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            } else {
              this.$swal({
                icon: "error",
                title: "保存失败!",
                text: "请联系管理员处理",
                customClass: {
                  confirmButton: "btn btn-error",
                },
              });
            }
          });
      } else {
        await useJwt
          .RebateConfigupdatebywxpay({
            cid: this.cid,
            mchId: this.mchId,
            mchKey: this.mchKey,
            keyPath: this.certname,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$swal({
                icon: "success",
                title: "保存!",
                text: "保存成功",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            } else {
              this.$swal({
                icon: "error",
                title: "保存失败!",
                text: "请联系管理员处理",
                customClass: {
                  confirmButton: "btn btn-error",
                },
              });
            }
          });
      }
    },
  },

  data() {
    return {
      jd_unionid: "",
      jd_appkey: "",
      jd_appkey_expire_time: "",
      alimama_accountid: "",
      dataoke_appkey: "",
      dataoke_appsecret: "",
      rcheck: true,
      clientid: "",
      clientsecret: "",
      waitkey: "",
      kltype: "",
      tjtype: "",
      tjoption: [
        { title: "不推荐", value: "0" },
        { title: "公众号推荐", value: "1" },
        { title: "小程序推荐", value: "2" },
      ],
      PaymentOrderSay: "",
      SettlementOrderSay: "",
      OrderCancellSay: "",
      CloseOrderSay: "",
      chatroomPaymentOrderSay: "",
      chatroomSettlementOrderSay: "",
      chatroomOrderCancellSay: "",
      chatroomOrderClose: "",
      agentSay: "",
      url: "",
      cid: 0,
      ck1: false,
      ck: false,
      option: [
        { title: "文字", value: "1" },
        { title: "自定义链接", value: "2" },
        { title: "大淘客链接", value: "5" },
        { title: "公众号链接", value: "3" },
        { title: "小程序链接", value: "4" },
      ],
      isfanoption: [
        { title: "是", value: "1" },
        { title: "否", value: "2" },
      ],
      tzoption: [
        { title: "H5网页跳转", value: "1" },
        { title: "小程序跳转", value: "2" },
      ],
      h5url: "",
      jdurl: "",
      tburl: "",
      pddurl: "",
      wphurl: "",
      sousuourl: "",
      appid: "",
      source: "",
      sourcename: "",
      icon: "",
      gzhname: "",
      gzhid: "",
      gzhappid: "",
      isfan: "",
      meituansid: "",
      commission: "",
      useryongjin: "",
      zhishuyongjin: "",
      feizhishuyongjin: "",
      moivezsyj: "",
      moivebl: "",
      mchId: "",
      mchKey: "",
      certname: "",
      meituansecret: "",
      meituanappkey: "",
      lianlianchannid: "",
      lianliansecret: "",
      pintotoappKey: "",
      pintotosecret: "",
      jutuikeapikey: "",
      haodankuapikey: "",
      haodankuV3apikey: "",
      haodankuV3secret: "",
      appSecret: "",
      appToken: "",
      appAESKey: "",
      file: null,
      tzlink: "",
      weappqrcodelink: "",
      wxqrcodelink: "",
      jutuikeindex: "",
      jutuikepubid: "",
    };
  },

  mounted() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt.queryAlimamaAccountByCid({ cid: this.cid }).then((res) => {
      console.log("res", JSON.stringify(res.data.data));
      if (res.data.data != null) {
        this.ck1 = true;
        this.jd_unionid = res.data.data.jdUnionid;
        this.jd_appkey = res.data.data.jdAppkey;
        this.jd_appkey_expire_time = res.data.data.jdAppkeyExpireTime;
        this.alimama_accountid = res.data.data.alimamaAccountid;
        this.dataoke_appkey = res.data.data.dataokeAppkey;
        this.dataoke_appsecret = res.data.data.dataokeAppsecret;
        this.rcheck = res.data.data.supportRelationid == "1" ? true : false;
        this.clientid = res.data.data.pddclientid;
        this.clientsecret = res.data.data.pddclientsecret;
        this.meituansecret = res.data.data.meituansecret;
        this.meituanappkey = res.data.data.meituanappkey;
        this.lianlianchannid = res.data.data.lianlianchannid;
        this.lianliansecret = res.data.data.lianliansecret;
        this.pintotoappKey = res.data.data.pintotoappKey;
        this.pintotosecret = res.data.data.pintotosecret;
        this.jutuikeapikey = res.data.data.jutuikeapikey;
        this.jutuikepubid = res.data.data.jutuikepubid;
        this.haodankuapikey = res.data.data.haodankuapikey;
        this.haodankuV3apikey = res.data.data.haodankuV3apikey;
        this.haodankuV3secret = res.data.data.haodankuV3secret;
        if (res.data.data.qrcodetype == "1") {
          this.tzlink = {
            title: "H5网页跳转",
            value: res.data.data.qrcodetype,
          };
        } else if (res.data.data.qrcodetype == "2") {
          this.tzlink = {
            title: "小程序跳转",
            value: res.data.data.qrcodetype,
          };
        }
        this.wxqrcodelink = res.data.data.wxqrcodelink;
        this.weappqrcodelink = res.data.data.weappqrcodelink;
        console.log(" res.data.jdUnionid", res.data.data.jdUnionid);
      }
    });
    useJwt.queryRebateConfigByCid({ cid: this.cid }).then((res) => {
      console.log("queryRebateConfigbycid", JSON.stringify(res.data.data));
      if (res.data.data != null) {
        console.log("res.data", JSON.stringify(res.data));
        this.ck = true;
        this.waitkey = res.data.data.searchwait;
        if (res.data.data.commandtype == "1") {
          this.kltype = { title: "文字", value: res.data.data.commandtype };
        } else if (res.data.data.commandtype == "2") {
          this.kltype = {
            title: "自定义链接",
            value: res.data.data.commandtype,
          };
        } else if (res.data.data.commandtype == "3") {
          this.kltype = {
            title: "公众号链接",
            value: res.data.data.commandtype,
          };
        } else if (res.data.data.commandtype == "4") {
          this.kltype = {
            title: "小程序链接",
            value: res.data.data.commandtype,
          };
        } else if (res.data.data.commandtype == "5") {
          this.kltype = {
            title: "大淘客链接",
            value: res.data.data.commandtype,
          };
        }

        if (res.data.data.tjtype == "0") {
          this.tjtype = {
            title: "不推荐",
            value: res.data.data.tjtype,
          };
        } else if (res.data.data.tjtype == "1") {
          this.tjtype = {
            title: "公众号推荐",
            value: res.data.data.tjtype,
          };
        } else if (res.data.data.tjtype == "2") {
          this.tjtype = {
            title: "小程序推荐",
            value: res.data.data.tjtype,
          };
        }

        if (res.data.data.isfan == "1") {
          this.isfan = {
            title: "是",
            value: res.data.data.isfan,
          };
        } else if (res.data.data.isfan == "2") {
          this.isfan = {
            title: "否",
            value: res.data.data.isfan,
          };
        }
        this.PaymentOrderSay = res.data.data.ordersucceeded;
        this.SettlementOrderSay = res.data.data.ordersettlement;
        this.OrderCancellSay = res.data.data.ordersrefund;
        this.CloseOrderSay = res.data.data.ordersclose;
        this.chatroomPaymentOrderSay = res.data.data.chatroomPaymentOrderSay;
        this.chatroomSettlementOrderSay =
          res.data.data.chatroomSettlementOrderSay;
        this.chatroomOrderClose = res.data.data.chatroomOrderClose;
        this.chatroomOrderCancellSay = res.data.data.chatroomOrderCancellSay;
        this.agentSay = res.data.data.agentrate;
        this.url = res.data.data.url;
        this.h5url = res.data.data.h5url;
        this.jdurl = res.data.data.jdurl;
        this.tburl = res.data.data.tburl;
        this.pddurl = res.data.data.pddurl;
        this.wphurl = res.data.data.wphurl;
        this.sousuourl = res.data.data.sousuourl;
        this.jutuikeindex = res.data.data.jutuikeindex;
        this.appid = res.data.data.appid;
        this.appSecret = res.data.data.appSecret;
        this.appToken = res.data.data.appToken;
        this.appAESKey = res.data.data.appAESKey;
        this.source = res.data.data.source;
        this.sourcename = res.data.data.sourcename;
        this.meituansid = res.data.data.meituansid;
        this.icon = res.data.data.icon;
        this.gzhname = res.data.data.gzhname;
        this.gzhid = res.data.data.gzhid;
        this.gzhappid = res.data.data.gzhappid;
        this.commission = res.data.data.commission;
        this.useryongjin = res.data.data.useryongjin;
        this.zhishuyongjin = res.data.data.zhishuyongjin;
        this.feizhishuyongjin = res.data.data.feizhishuyongjin;
        this.moivezsyj = res.data.data.moivezsyj;
        this.moivebl = res.data.data.moivebl;
        this.mchId = res.data.data.mchId;
        this.mchKey = res.data.data.mchKey;
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
